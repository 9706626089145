<template>
  <master-layout
    smallTitle="nuova"
    bigTitle="Rilevazione"
    titleStyle="horizontal"
  >
    <div class="relative">
      <div class="grid-center mb-4">
        <label v-if="loading">Caricamento delle immagini in corso...</label>
        <button
          v-else
          @click="triggerFileUploader"
          class="px-4 py-3 button-primary"
        >
          {{
            rilevazione.elementiMultimediali.length > 0
              ? "Tocca per caricare altre immagini"
              : "Tocca per caricare immagini"
          }}
        </button>

        <input
          style="display: none"
          id="images-uploader"
          type="file"
          name="filefield"
          multiple
          @change="handleFileInput"
        />
      </div>
      <span v-if="rilevazione.elementiMultimediali.length > 0"
        >Hai caricato delle immagini, scrolla in fondo alla pagina per vederne
        la miniatura</span
      >
      <!-- <PictureInput
        ref="pictureInput"
        :plain="true"
        width="300"
        height="300"
        accept="image/*"
        size="10"
        :crop="false"
        radius="3"
        :removable="true"
        button-class="btn"
        @change="handleFileInput"
      >
      </PictureInput> -->
      <div class="ion-margin-bottom ion-padding-bottom">
        <ion-row class="ion-margin-top ion-padding-top">
          <ion-col>
            <ion-item>
              <ion-textarea
                :autoGrow="true"
                :rows="2"
                placeholder="Descrizione"
                v-model="rilevazione.descrizione"
              ></ion-textarea>
            </ion-item>
            <ion-item class="ion-margin-top">
              <ion-input
                placeholder="Luogo"
                inputmode="text"
                @ionFocus="setFocusTrue"
                @ionBlur="setFocusFalse"
                maxlength="50"
                v-model="rilevazione.luogo"
              ></ion-input>
            </ion-item>
            <ion-item class="ion-margin-top ion-margin-bottom">
              <ion-datetime
                :min="minDate"
                :max="maxDate"
                @ionChange="handleDateTimeChange($event)"
              ></ion-datetime>
            </ion-item>
          </ion-col>
        </ion-row>
      </div>

      <div class="galleria pb-6">
        <div
          @click="showModal(index)"
          class="gal-image"
          v-for="(image, index) in rilevazione.elementiMultimediali"
          :key="index"
        >
          <img :src="image.urlElemento" alt="" />
        </div>
      </div>

      <!-- <ion-row
        v-if="!loading"
        class="ion-justify-content-center ion-margin-top ion-padding-top"
      >
        <ion-col size="auto">
          <ion-button :disabled="loading" @click="salvaRilevazione">
            Salva</ion-button
          >
        </ion-col>
      </ion-row> -->
      <button
        :disabled="loading || disabled"
        @click="salvaRilevazione"
        class="fixed bottom center button-bottom-axerta-secondary w-75 text-16"
      >
        Salva
      </button>
    </div>
    <div id="bottom-component" class="bottom-component">
      <div v-if="tastieraFocus"></div>
    </div>
  </master-layout>
</template>

<script setup>
import { ref, computed } from "vue"
import { useRoute, useRouter } from "vue-router"
import MasterLayout from "../../components/MasterLayout.vue"
import { toastController } from "@ionic/vue"
import PictureInput from "vue-picture-input"
// import EXIF from 'exif-js'
import ApiService from "../../common/service.api"
import { cloudUpload } from "ionicons/icons"

import {
  IonAvatar,
  IonLabel,
  IonSelectOption,
  IonRow,
  IonCol,
  IonCard,
  IonList,
  IonItem,
  IonInput,
  IonSelect,
  IonTextarea,
  IonIcon,
  IonButton,
  IonFab,
  IonFabButton,
  modalController,
  IonDatetime,
  //   IonRouterOutlet,
  loadingController,
  IonLoading,
} from "@ionic/vue"

const route = useRoute()
const router = useRouter()

const tastieraFocus = ref(false)

function setFocusTrue() {
  tastieraFocus.value = true
  scrollDown()
}
function setFocusFalse() {
  tastieraFocus.value = false
}
function scrollDown() {
  document
    .querySelector("#bottom-component")
    .scrollIntoView({ behavior: "smooth" })
}

const disabled = computed(() => {
  if (
    rilevazione.value.luogo.length > 0 &&
    rilevazione.value.descrizione.length > 0 &&
    rilevazione.value.elementiMultimediali.length > 0 &&
    rilevazione.value.dataOra.length > 0
  ) {
    return false
  }
  return true
})

const rilevazione = ref({
  descrizione: "",
  elementiMultimediali: [],
  luogo: "",
  dataOra: "",
  stato: "3",
})

const minDate = computed(() => {
  return new Date(route.params.inizioIntervento).toISOString().slice(0, 19)
})

const maxDate = computed(() => {
  if (route.params.fineIntervento == "in-corso") {
    let year = new Date().getFullYear()
    let month = new Date().getMonth() + 1
    if (month < 10) month = "0" + month
    let day = new Date().getDate()
    let hour = new Date().getHours()
    if (hour < 10) hour = "0" + hour
    let minute = new Date().getMinutes()
    if (minute < 10) minute = "0" + minute

    return year + "-" + month + "-" + day + "T" + hour + ":" + minute
  } else {
    return new Date(route.params.fineIntervento).toISOString().slice(0, 19)
  }
})

const modalVisible = ref(false)
const currentImageIndex = ref("")

function handleDateTimeChange(event) {
  let ora = event.detail.value

  rilevazione.value.dataOra = ora
}

const loading = ref(false)

function handleFileInput(event) {
  loading.value = true
  const promises = []

  for (let i = 0; i < event.target.files.length; i++) {
    const formData = new FormData()
    formData.append("file", event.target.files[i])
    promises.push(
      ApiService.post("Upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
    )
  }

  Promise.all(promises)
    .then((values) => {
      values.forEach((value) => {
        rilevazione.value.elementiMultimediali.push({
          urlElemento: "https://api-axerta.whappy.it/Drive/" + value.data,
        })
      })
    })
    .finally(() => {
      loading.value = false
      presentToast("File caricato")
    })
}

function salvaRilevazione() {
  loading.value = true
  disabled.value = true
  ApiService.post(
    "DossierManager/AddRilevazioneDettaglio/" + route.params.idIntervento,
    rilevazione.value
  )
    .then((res) => {
      console.log(" success salva rilevazione ", res)
    })
    .finally(() => {
      rilevazione.value = {
        descrizione: "",
        elementiMultimediali: [],
        luogo: "",
        dataOra: "",
      }
      loading.value = false
      disabled.value = false
      router.push(
        {
          name: "ElencoRilevazioni",
          params: {
            idOrdine: route.params.idOrdine,
            idIntervento: route.params.idIntervento,
            inizioIntervento: route.params.inizioIntervento,
            fineIntervento: route.params.fineIntervento
              ? route.params.fineIntervento
              : "in-corso",
          },
        }

        // "/axerta/elenco-rilevazioni/" +
        //   route.params.idOrdine +
        //   "/" +
        //   route.params.idIntervento
      )
    })
}

async function presentToast(message) {
  const toast = await toastController.create({
    message: message,
    duration: 1500,
    position: "bottom",
    color: "success",
  })

  await toast.present()
}

function triggerFileUploader() {
  document.getElementById("images-uploader").click()
}

// function salvaRilevazione() {
//   ApiService.post(
//     "DossierManager/SaveRilevazione/" + route.params.idRilevazione,
//     rilevazione.value
//   )
//     .then((res) => {
//       console.log(
//         "'DossierManager/SaveRilevazione/'+route.params.idRilevazione",
//         res
//       );
//     })
//     .finally(() => {
//       router.go(-1);
//     });
// }
</script>

<style lang="scss" scoped>
.hello {
  position: relative;
}

.icon {
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
}

.galleria {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
  padding: 10px;
}

.gal-image {
  max-width: 30%;
  min-width: 100px;

  img {
    width: 100%;
    height: auto;
    border: 3px solid gray;
    border-radius: 5px;
  }
}

.active {
  img {
    border: 4px solid rgb(85, 194, 85);
  }
}

.relative {
  position: relative;
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 900000;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding: 1rem;
  background-color: rgb(255, 255, 255);
  // border: 2px solid gray;
  border-radius: 10px;
  box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2);

  .header {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .image-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
    padding: 1rem 0;
  }

  .current-image {
    display: grid;
    place-content: center;
  }
}

input[type="file"] {
  border: 1px solid rgb(165, 165, 165);
  border-radius: 7px;
  padding: 1rem 2rem;
}

// .custom-file-upload {
//   border: 1px solid #ccc;
//   display: inline-block;
//   padding: 6px 12px;
//   margin-bottom: 3rem;
//   border-radius: 5px;
//   padding: 0.5rem 1rem;
//   cursor: pointer;
//   box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06),
//     0px 2px 6px rgba(0, 0, 0, 0.12);
// }

.bottom-component {
  width: 100%;
  padding-top: 50vh;
}
</style>
